export const INIT_WISHLIST_BY_USER_ID = "INIT_WISHLIST_BY_USER_ID";
export const POST_WISHLIST = "POST_WISHLIST";
export const ADD_WISHLIST_ITEM = "ADD_WISHLIST_ITEM";
export const ADD_WISHLIST_TEMPORARY = "ADD_WISHLIST_TEMPORARY";
export const REMOVE_WISHLIST_ITEM = "REMOVE_WISHLIST_ITEM";
export const SET_CURRENT_WISHLIST = "SET_CURRENT_WISHLIST";
export const SET_IS_LOADING = "SET_IS_LOADING";
export const REMOVE_WISHLIST = "REMOVE_WISHLIST";
export const UPDATE_WISHLIST = "UPDATE_WISHLIST";
export const POST_WISHLIST_GUEST = "POST_WISHLIST_GUEST";
export const CHANGE_WISHLIST_COLOR = "CHANGE_WISHLIST_COLOR";